import styles from "./Main.module.scss";
import checkbox from "../../assets/img/svg/checkbox.svg";
import yes from "../../assets/img/svg/yes.svg";
import cross from "../../assets/img/svg/cross.svg";
import arrow1 from "../../assets/img/svg/arrows/arrow1.svg";
import arrow2 from "../../assets/img/svg/arrows/arrow2.svg";
import ok from "../../assets/img/svg/ok.svg";
import shop from "../../assets/img/svg/icons/shop.svg";
import chat from "../../assets/img/svg/icons/chat.svg";
import school from "../../assets/img/svg/icons/school.svg";
import subttasks from "../../assets/img/svg/icons/subttasks.svg";
import support from "../../assets/img/svg/icons/support.svg";
import lineOrange from "../../assets/img/svg/lineOrange.svg";
import arrowDownWhite from "../../assets/img/svg/arrows/arrowDownWhite.svg";
import arrowDownBlack from "../../assets/img/svg/arrows/arrowDownBlack.svg";
import arrow from "../../assets/img/svg/arrows/arrow.svg";
import brakets from "../../assets/img/svg/icons/brakets.svg";
import cl from "classnames";

export const Main: React.FC = () => {
	return (
		<div className={styles.content}>
			<div className={styles.mainContent}>
				<h2>
					We are the first <span>decentralized</span> <span>web 2.5</span>{" "}
					foundation
				</h2>
				<div className={styles.text}>
					<div className={styles.gamingText}>
						<h1>GAMING</h1>
						<div className={styles.textLine}></div>
					</div>
					<div>
						<h6>
							Blockchain-powered foundation where Web2 meets Web3 to create a
							new ecosystem
						</h6>
						<h6 className={styles.btn}>Join the Foundation</h6>
					</div>
				</div>
			</div>
			<div className={styles.worlds}>
				<h2>We take the best of both worlds</h2>
				<div className={styles.worldsBg}>
					<div className={styles.worldsContent}>
						<div>
							<h3>
								We are focused on solving real problems using{" "}
								<span>blockchain technology</span>
							</h3>
							<h6>
								We, as founders and creators from different fields, have all
								been working on bringing together the audience from Web2 and
								Web3. Now we aim to help others integrate blockchain features
								into their web2 projects.
							</h6>
						</div>
						<div className={styles.cards}>
							<div className={styles.cardBlue}>
								<div className={styles.notes}>
									<h6>What We Do</h6>
									<div className={styles.items}>
										<div className={styles.item}>
											<img src={checkbox} alt="" />
											<p>Do research and collect data</p>
										</div>
										<div className={styles.item}>
											<img src={checkbox} alt="" />
											<p>Provide tech help</p>
										</div>
										<div className={styles.item}>
											<img src={checkbox} alt="" />
											<p>Offer funding</p>
										</div>
										<div className={styles.item}>
											<img src={checkbox} alt="" />
											<p>Our experts share their expertise on your project</p>
										</div>
									</div>
								</div>
								<h6>
									We offer simple and clear solutions suitable for your projects
								</h6>
							</div>
							<div className={styles.cardWhite}>
								<div className={styles.rows}>
									<div className={styles.row}>
										<h5>AI</h5>
									</div>
									<div className={styles.row}>
										<h5>Gaming</h5>
									</div>
									<div className={styles.row}>
										<h5>Payment Integration</h5>
									</div>
									<div className={styles.row}>
										<h5>+17 others</h5>
									</div>
								</div>
								<h6>
									Each project is assigned a team of experts experienced in the
									chosen industry
								</h6>
							</div>
							<div className={styles.cardOrange}>
								<div className={styles.cardContent}>
									<h5>All foundation decisions are made through DAO</h5>
									<div className={styles.decision}>
										<div>
											<img src={cross} alt="" />
										</div>
										<div>
											<img src={yes} alt="" />
										</div>
									</div>
								</div>
								<h6>We use blockchain to vote and distribute funds</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.questionsBlock}>
				<h2>How it works</h2>
				<div className={styles.questions}>
					<div className={styles.card1}>
						<h3>1</h3>
						<h4>We receive your request and put it on vote on DAO</h4>
						<h6>
							Members of the foundation make their decision on if we can help
							you. If the project passes, we start move on to the next step.
						</h6>
						<img src={arrow1} alt="" />
					</div>
					<div className={styles.card2}>
						<h3>2</h3>
						<h4>Assign a team of experts to the project</h4>
						<h6>
							We choose professionals based on your project’s specifics and
							needs
						</h6>
						<img src={arrow2} alt="" />
					</div>
					<div className={styles.card3}>
						<h3>3</h3>
						<h4>Do research and determine how we can help</h4>
						<h6>
							Team assigned to your project works in collaboration with you to
							decide on how to meet your request
						</h6>
						<img src={arrow1} alt="" />
					</div>
					<div className={styles.card4}>
						<h3>4</h3>
						<h4>Deliver a solution to you</h4>
						<h6>
							It might be funding, technical help. something else or it all
							combined
						</h6>
						<img src={ok} alt="" />
					</div>
				</div>
			</div>
			<div className={styles.activitiesBlock}>
				<div>
					<h2>Our</h2>
					<h2 className={styles.core}>core</h2>
					<h2>activities</h2>
				</div>
				{/* <img src={lineOrange} alt="" /> */}
				<div className={styles.activities}>
					<div className={styles.activity}>
						<img src={shop} alt="" />
						<h5>Multichain Marketplace</h5>
					</div>
					<div className={styles.activity}>
						<img src={school} alt="" />
						<h5>Free Education</h5>
					</div>
					<div className={styles.activity}>
						<img src={support} alt="" />
						<h5>Grant Support</h5>
					</div>
					<div className={styles.activity}>
						<img src={chat} alt="" />
						<h5>Consulting services</h5>
					</div>
					<div className={styles.activity}>
						<img src={subttasks} alt="" />
						<h5>Blockchain integration & tech support</h5>
					</div>
				</div>
			</div>
			<div className={styles.focusBlock}>
				<div className={styles.icons}>
					<div>
						<h4>AI</h4>
						<h4>Gaming</h4>
					</div>
					<div>
						<h4>Education</h4>
						<h4>Analytics</h4>
					</div>
					<h4>Food Service</h4>
					<div>
						<h4>Entertainment</h4>
						<h4>Ecology</h4>
					</div>
				</div>
				<div className={styles.focus}>
					<h2>Our focus areas</h2>
					<h6>
						These are not the only areas we are working in but those are our
						priorities. To maximize societal impact, our work is strategically
						focused across these flagship initiatives, although not limited to
						them.
					</h6>
					<h6>Join Web 2.5</h6>
				</div>
			</div>
			<div className={styles.ecosystemBlock}>
				<h2>Ecosystem</h2>
				<div className={styles.ecosystem}>
					<div className={styles.sorts}>
						<div className={styles.projectsText}>
							<h6>All Projects</h6>
							<img src={arrowDownWhite} alt="" />
						</div>
						<div className={styles.sort}>
							<h6>Sort By:</h6>
							<h6>Most Recent</h6>
							<img src={arrowDownBlack} alt="" />
						</div>
					</div>
					<div className={styles.projects}>
						<div className={styles.project}>
							<div className={styles.block}>
								<div className={styles.projectTitle}>
									<h4>Pixudi</h4>
									<div>
										<img src={arrow} alt="" />
									</div>
								</div>
								<h6>Blockchain-based Play2Collect board game</h6>
								<div>
									<h6>Gaming</h6>
									<h6>Entertainment</h6>
									<h6>Launched</h6>
								</div>
							</div>
						</div>
						<div className={styles.project}>
							<div className={styles.brakets}>
								<div className={styles.braketsImg}>
									<img src={brakets} alt="" />
								</div>
								<h4>Brakets</h4>
							</div>
							<div className={cl(styles.block, styles.braketsBlock)}>
								<div className={styles.projectTitle}>
									<h4>Brakets</h4>
									<div>
										<img src={arrow} alt="" />
									</div>
								</div>
								<h6>eSports venue booking platform powered by blockchain</h6>
								<div>
									<h6>eSports</h6>
									<h6 className={styles.booking}>Booking</h6>
									<h6 className={styles.progress}>In Progress</h6>
								</div>
							</div>
						</div>
						<div className={styles.project}>
							<div className={cl(styles.block, styles.artAurum)}>
								<div className={styles.projectTitle}>
									<h4>Art Aurum</h4>
									<div>
										<img src={arrow} alt="" />
									</div>
								</div>
								<h6>
									Auction house with offices all around the world that conducts
									auctions in different fields
								</h6>
								<div>
									<h6>Art</h6>
									<h6>Marketplace</h6>
									<h6>Launched</h6>
								</div>
							</div>
						</div>
						<div className={styles.project}>
							<div className={styles.trotskyTitle}>
								<div>Trotsky’s</div>
								<div>Food with soul</div>
							</div>
							<div className={cl(styles.block, styles.trotsky)}>
								<div className={styles.projectTitle}>
									<h4>Trotsky’s</h4>
									<div>
										<img src={arrow} alt="" />
									</div>
								</div>
								<h6>
									Dumplings delivery and takeaway in Playa del Carmen, Mexico
								</h6>
								<div>
									<h6>Hospitality</h6>
									<h6>Restaurant</h6>
									<h6>Launched</h6>
								</div>
							</div>
						</div>
						<div className={styles.project}>
							<div className={cl(styles.block, styles.mami)}>
								<div className={styles.projectTitle}>
									<h4>Mami</h4>
									<div>
										<img src={arrow} alt="" />
									</div>
								</div>
								<h6>
									App for creating a customized menu based on user preferences,
									parameters and allergies.
								</h6>
								<div>
									<div>
										<h6>Mobile App</h6>
										<h6>AI</h6>
										<h6>Meal Planning</h6>
									</div>
									<h6>In Progress</h6>
								</div>
							</div>
						</div>
						<div className={styles.project}>
							<div className={cl(styles.block, styles.deform)}>
								<div className={styles.projectTitle}>
									<h4>Deform AI</h4>
									<div>
										<img src={arrow} alt="" />
									</div>
								</div>
								<h6>Revolutionary Text2Video AI-powered platform</h6>
								<div>
									<h6>AI</h6>
									<h6>Entertainment</h6>
									<h6>In Progress</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
