import { Route, Routes } from "react-router-dom";
import { Main } from "../../pages/Main/Main";
import { Header } from "../../components/common/Header/Header";

export function AppRoutes() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<>
						<Header />
						<Main />
					</>
				}
			/>
		</Routes>
	);
}
