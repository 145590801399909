import styles from "./Header.module.scss";
import menu from "../../../assets/img/svg/menu.svg";

export const Header: React.FC = () => {
	return (
		<div className={styles.content}>
			<h6>Functionality</h6>
			<h6>Analytics</h6>
			<h6>Roadmap</h6>
			<h6>Tokenomics</h6>
			<h6 className={styles.btn}>Join Web 2.5</h6>
			<img src={menu} alt="" className={styles.menu} />
		</div>
	);
};
